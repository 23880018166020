<template>
  <div class="navigation-view-container">
    <div class="navigation-view-paneParent">
      <div
        class="navigation-view-pane"
        v-on-clickaway="hideMenu"
        :style="{ height: leftMenuHeight }"
        :class="[shrinkLeftMenu ? 'shrink' : '']"
      >
        <div class="navigation-view-paneTop">
          <div class="navigation-view-topIcon">
            <template v-if="!share">
              <span
                :class="
                  mobile
                    ? android
                      ? 'iconFont mobile android'
                      : 'iconFont mobile'
                    : 'iconFont'
                "
                @click="shrinkMenu"
              >
                <!-- <i class="fa fa-bars"></i> -->
                <!-- Custom font-face to serve the fa fa-bars icon directly for better UX -->
                <i class="cfa cfa-bars"></i>
              </span>
              <div
                :class="
                  mobile
                    ? android
                      ? 'navigation-view-pageTitle mobile android'
                      : 'navigation-view-pageTitle mobile'
                    : 'navigation-view-pageTitle'
                "
                @click="goHome"
              >
                &nbsp;{{ $t("00003") }}
              </div>
            </template>
            <template v-else>
              <div
                :class="
                  mobile
                    ? 'navigation-view-pageTitle mobile'
                    : 'navigation-view-pageTitle'
                "
                style="text-align: center"
              >
                &nbsp;{{ $t("00003") }}&nbsp;{{
                  `${share ? `${platform} APP` : ""}`
                }}
              </div>
            </template>
          </div>
          <div
            class="navigation-view-paneTopIcons"
            :class="[shrinkLeftMenu ? 'shrink' : '']"
          >
            <div
              class="search-container"
              :class="[shrinkLeftMenu ? 'shrink' : '']"
            >
              <form action="/">
                <van-search
                  v-model="searchValue"
                  shape="round"
                  :clearable="true"
                  background="transparent"
                  :placeholder="$t('00000')"
                  @search="onSearch"
                  @clear="onClearSearch"
                  @focus="onFocus"
                  @blur="onBlur"
                />
              </form>
              <div class="no-search-result" v-if="searchWarning">
                Sorry! No search result.
              </div>
            </div>
            <scroll-div
              height="calc(100vh - 155px)"
              width="280px"
              :useNative="false"
              :barStyle="{
                backgroundColor: '#73777B',
              }"
            >
              <template v-if="hasSearchResult">
                <search-item
                  ref="leftMenuItem"
                  v-for="item in searchResult"
                  :key="item.Painting_Index"
                  :paintingInfo="item"
                  :shrinkLeftMenu="shrinkLeftMenu"
                />
              </template>
              <template v-else-if="showCollection">
                <div class="loading-svg" v-if="!hasCollectionResult">
                  <img :src="'/img/icons/loading.svg'" />
                </div>
                <collection-item
                  ref="leftMenuItem"
                  v-for="item in collectionResult"
                  :key="item.Painting_Index"
                  :paintingInfo="item"
                  :shrinkLeftMenu="shrinkLeftMenu"
                />
              </template>
              <template v-else>
                <left-menu-item
                  ref="leftMenuItem"
                  v-for="item in genreData"
                  :key="item"
                  :textString="item"
                  :shrinkLeftMenu="shrinkLeftMenu"
                  :customIcon="genre === item ? 'fa fa-star' : 'fal fa-star'"
                />
              </template>
            </scroll-div>
          </div>
        </div>
        <div
          class="navigation-view-paneTopIcons flex-layout"
          :class="[shrinkLeftMenu ? 'shrink' : '']"
        >
          <left-menu-item
            customStyle="width: 100%"
            :textString="leftMenuItemTextString"
            :shrinkLeftMenu="shrinkLeftMenu"
            customIcon="fas fa-user-alt"
            @clearSearch="onClearSearch"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as revealEffect from "@/assets/reveal-effect/index";
import { mixin as clickaway } from "vue-clickaway";
import LeftMenuItem from "./item.vue";
import SearchItem from "./searchItem.vue";
import CollectionItem from "./collectionItem.vue";
import ScrollDiv from "vue-scroll-div";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LeftMenu",

  mixins: [clickaway],

  components: {
    LeftMenuItem,
    SearchItem,
    CollectionItem,
    ScrollDiv,
  },

  data() {
    return {
      inputing: false,
      mobile: false,
      ipad: false,
      android: false,
      searchValue: "",
      searchWarning: false,
      shrinkLeftMenu: true,
      genreData: [
        "Gothic Art",
        "The Early Renaissance",
        "The Northern Renaissance",
        "The Booming Renaissance",
        "Mannerism",
        "Baroque Art",
        "Rococo Art",
        "Neoclassicism",
        "Academic Art",
        "Romanticism",
        "The Pre-Raphaelite Brotherhood",
        "Classicism",
        "Symbolism",
        "Realism",
        "The Barbizon School",
        "Impressionism",
        "Post-Impressionism",
        "Pointillism",
        "Art Nouveau",
        "Les Nabis",
        "Expressionism",
        "Cubism",
        "Futurism",
        "Neo-Plasticism",
        "Fauvism",
        "Surrealism",
        "Others",
      ],
    };
  },

  computed: {
    ...mapGetters({
      share: "getShare",
      genre: "getGenre",
      searchResult: "getSearchResult",
      showCollection: "getShowCollection",
      collectionResult: "getCollectionResult",
      hardwareBackActive: "getHardwareBackActive",
    }),

    hasSearchResult() {
      const { searchResult } = this;
      return Array.isArray(searchResult) && searchResult.length > 0;
    },

    hasCollectionResult() {
      const { collectionResult } = this;
      return Array.isArray(collectionResult) && collectionResult.length > 0;
    },

    leftMenuItemTextString() {
      return this.showCollection ? this.$t("00002") : this.$t("00001");
    },

    leftMenuHeight() {
      return `${window.innerHeight}px`;
    },

    platform() {
      return this.android ? "Android" : "iOS";
    },
  },

  watch: {
    searchValue(newValue) {
      !newValue && this.onClearSearch();
    },
    hardwareBackActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.shrinkLeftMenu = true;
          this.updateShrinkLeftMenu(true);
        }
      },
    },
  },

  methods: {
    ...mapActions([
      "fetchSearchResult",
      "clearSearchResult",
      "toggleShowCollection",
      "updateShrinkLeftMenu",
    ]),

    goHome() {
      // this.$router.push("/");
    },

    shrinkMenu() {
      this.shrinkLeftMenu = !this.shrinkLeftMenu;
      if (this.shrinkLeftMenu) {
        // hide
        setTimeout(() => {
          this.updateShrinkLeftMenu(this.shrinkLeftMenu);
        }, 300); // smoother animation
      } else {
        // show
        setTimeout(() => {
          this.updateShrinkLeftMenu(this.shrinkLeftMenu);
        }, 100); // smoother animation
      }
    },

    hideMenu() {
      this.shrinkLeftMenu = true;
      setTimeout(() => {
        this.updateShrinkLeftMenu(this.shrinkLeftMenu);
      }, 300); // smoother animation
    },

    async onSearch() {
      this.searchValue = this.searchValue.trim();
      if (this.searchValue.length > 0) {
        await this.fetchSearchResult(this.searchValue);
        if (!this.hasSearchResult) {
          // Show search warning for 1.5 seconds when no result
          this.searchWarning = true;
          setTimeout(() => {
            this.searchWarning = false;
          }, 1500);
        }

        // Ensure hiding My Local Collections after onSearch
        if (this.showCollection) {
          this.toggleShowCollection();
        }
      }
    },

    onClearSearch() {
      this.searchValue = "";
      this.clearSearchResult();
    },

    // Fix phone keyboard pop up issue #213
    onFocus() {
      this.inputing = true;
    },

    onBlur() {
      this.inputing = false;
    },
  },

  created() {
    this.mobile = window.innerWidth <= 450;
    this.ipad =
      !!navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/) || // browser devtools ipad
      (/Macintosh/i.test(navigator.userAgent) && // real ipad devices
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 1);
    this.android = Boolean(
      window.navigator.userAgent.match(/Android/i) &&
        window.navigator.userAgent.match(/Mobile/i)
    );
  },

  mounted() {
    // if (!this.mobile) {
    //   // revealEffect.setRevealConfig({});
    //   // revealEffect.addRevealEl(this.$refs.leftMenuItemContainerBlock);
    //   const revealItems = this.$refs.leftMenuItem.map((vm) => ({
    //     element: vm.$refs.leftMenuItemContainerBlock,
    //     hoverSize: 200,
    //     hoverColor: "rgba(255, 255, 255, .4)",
    //     borderWidth: 0,
    //   }));
    //   revealEffect.addRevealItems(revealItems);
    //   // revealEffect.addRevealItem({
    //   //   element: this.$refs.leftMenuItemContainerBlock,
    //   //   hoverSize: 200,
    //   //   hoverColor: "rgba(255, 255, 255, .4)",
    //   //   borderWidth: 2,
    //   // });
    // }
  },

  updated() {
    // if (!this.mobile) {
    //   const revealItems = this.$refs.leftMenuItem.map((vm) => ({
    //     element: vm.$refs.leftMenuItemContainerBlock,
    //     hoverSize: 200,
    //     hoverColor: "rgba(255, 255, 255, .4)",
    //     borderWidth: 0,
    //   }));
    //   revealEffect.addRevealItems(revealItems);
    // }
  },
};
</script>

<style lang="less" scoped>
.navigation-view-container {
  position: fixed;
  width: 56px;
  height: 100%;
  z-index: 50;

  .navigation-view-paneParent {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    flex: 0 0 auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    height: 48px;
    // z-index: 50;
    transition: width 0.3s ease-in-out 0s;

    .navigation-view-pane {
      background: url(https://www.boost-art.net/img/Whistler-Nocturne_in_black_and_gold.png)
          left top / cover no-repeat scroll,
        url(https://www.boost-art.net/img/5aec7241-37f4-471f-bb91-3089c82f5e4c.png)
          left top repeat fixed;
      background-blend-mode: overlay;
      box-shadow: rgb(0 0 0 / 28%) 0px 0px 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 280px;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: width 0.3s ease-in-out 0s;

      &.shrink {
        width: 0;
      }

      .navigation-view-paneTopIcons {
        overflow: hidden;
        width: 280px;
        flex: 0 0 auto;
        z-index: 50;

        &.flex-layout {
          display: flex;
          flex-direction: column;
        }

        &.shrink {
          width: 0;
        }

        .search-container {
          width: 100%;
          transition: width 0.3s ease-in-out 0s;
          position: relative;

          &.shrink {
            width: 0;
          }

          .no-search-result {
            color: #dc3545;
            position: absolute;
            bottom: -12px;
            left: 30px;
            font-size: 12px;
          }
        }

        .loading-svg {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .navigation-view-paneTop {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 0 0 auto;

        .navigation-view-topIcon {
          // background: url(https://www.boost-art.net/img/Whistler-Nocturne_in_black_and_gold.png)
          //     left top / cover no-repeat fixed,
          //   url(https://www.boost-art.net/img/5aec7241-37f4-471f-bb91-3089c82f5e4c.png)
          //     left top repeat fixed;
          // background-blend-mode: overlay;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          flex: 0 0 auto;
          z-index: 50;

          .iconFont {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            font-family: "Font Awesome 5 Free";
            font-size: 16px;
            transition: all 0.3s ease 0s;
            border: none;
            outline: none;
            user-select: none;
            width: 56px;
            height: 56px;
            line-height: 56px;
            font-size: 16px;
            color: rgb(255, 255, 255);
            cursor: default;
            flex: 0 0 auto;

            .cfa {
              font-weight: 900;
              font-family: "FontAwesome";
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
            }

            .cfa-bars:before {
              content: "\f0c9";
            }

            &:not(.mobile):hover {
              background: rgba(255, 255, 255, 0.2);
            }

            &:not(.mobile):active {
              background: rgba(255, 255, 255, 0.4);
            }

            &.android {
              background: #546f5b;

              i:hover::after {
                background-color: #546f5b;
              }
            }
            &.mobile {
              // background: #546f5b;

              i {
                position: relative;

                &::after {
                  position: absolute;
                  content: "";
                  height: 14px;
                  width: 14px;
                  top: 1px;
                  left: 0;
                  border-radius: 50%;
                  transform: scale(2.5);
                  z-index: -1;
                }

                &:hover::after {
                  // background-color: #546f5b;
                }
              }

              &:active i::after {
                background-color: rgba(17, 41, 22, 0.4);
                z-index: 1;
              }
            }
          }

          .navigation-view-pageTitle {
            padding-left: 2px;
            opacity: 1;
            width: 280px;
            overflow-wrap: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgb(255, 255, 255);
            cursor: pointer;
            user-select: none;
            font-size: 20px;

            &.android {
              background: #546f5b;
            }

            &.mobile {
              display: block;
              min-width: 100vw;
              height: 56px;
              line-height: 56px;
              font-weight: 500;
              letter-spacing: 0.0125em;
              // background: #546f5b;
            }
          }
        }
      }
    }
  }
}
</style>
