<template>
  <span
    class="left-menu-item-container"
    :class="[shrinkLeftMenu ? 'shrink' : '', mobile ? 'mobile' : '']"
    :style="customStyle"
    ><span class="block" ref="leftMenuItemContainerBlock"
      ><div class="split-view-command" @click.stop="clickAction()">
        <span
          ><i class="iconFont" :class="[customIcon ? customIcon : '']"></i
        ></span>
        <div
          class="split-view-command-label"
          :class="[shrinkLeftMenu ? 'shrink' : '']"
        >
          {{ textString }}
        </div>
      </div></span
    ></span
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LeftMenuItem",

  props: {
    shrinkLeftMenu: {
      type: Boolean,
      default: false,
    },
    textString: {
      type: String,
      default: "",
    },
    customIcon: {
      type: String,
      default: "",
    },
    customStyle: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mobile: false,
      genreList: [
        "Others",
        "Surrealism",
        "Fauvism",
        "Neo-Plasticism",
        "Futurism",
        "Cubism",
        "Expressionism",
        "Les Nabis",
        "Art Nouveau",
        "Pointillism",
        "Post-Impressionism",
        "Impressionism",
        "The Barbizon School",
        "Realism",
        "Symbolism",
        "Classicism",
        "The Pre-Raphaelite Brotherhood",
        "Romanticism",
        "Academic Art",
        "Neoclassicism",
        "Rococo Art",
        "Baroque Art",
        "Mannerism",
        "The Booming Renaissance",
        "The Northern Renaissance",
        "The Early Renaissance",
        "Gothic Art",
      ],
    };
  },

  computed: {
    ...mapGetters({
      showCollection: "getShowCollection",
    }),
  },

  methods: {
    ...mapActions([
      "switchGenre",
      "fetchCollectionResult",
      "toggleShowCollection",
      "clearCollectionResult",
    ]),

    async clickAction() {
      if (this.genreList.includes(this.textString)) {
        this.switchGenre(this.textString);
        this.$router.push(`/genre/${this.textString}`);
      } else {
        console.log("My Local Collections");
        this.toggleShowCollection();
        if (this.showCollection === true) {
          // const token = this.$cookie.get("boost-art-net-token") || "";
          // await this.fetchCollectionResult(token);
          await this.fetchCollectionResult();
          this.$emit("clearSearch");
        } else {
          this.clearCollectionResult();
        }
      }
    },
  },

  created() {
    this.mobile = window.innerWidth <= 450;
  },
};
</script>

<style lang="less" scoped>
.left-menu-item-container {
  display: block;
  width: ~"calc(100% - 9px)";
  //vertical-align: middle;
  overflow: hidden;
  border: 2px solid transparent;
  &.mobile {
    width: 100%;
  }
  &.shrink {
    position: relative;
    left: -280px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.25);
  }
  &:hover,
  &:active {
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  .block {
    height: 48px;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    .split-view-command {
      flex: 0 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      border-style: solid;
      border-color: transparent;
      border-image: initial;
      border-width: 2px 0px;
      background: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      cursor: pointer;
      .iconFont {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-family: "Font Awesome 5 Free";
        font-size: 16px;
        transition: all 0.25s ease 0s;
        border: none;
        outline: none;
        user-select: none;
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        color: rgb(255, 255, 255);
        cursor: default;
        flex: 0 0 auto;
      }
      .split-view-command-label {
        user-select: none;
        color: rgb(255, 255, 255);
        opacity: 1;
        transition: opacity 0.35s ease-in 0s;
        &.shrink {
          opacity: 0;
        }
      }
    }
  }
}
</style>
