<template>
  <footer class="footer">
    <div class="app-badge">
      <div class="center">
        <p class="tagline">
          Art Widget for →<br />
          Your iPhone/iPad
        </p>
      </div>
      <a
        href="https://apps.apple.com/us/app/boost-art-net-art-destination/id6467807254?platform=iphone"
        target="_blank"
        rel="noopener noreferrer"
        ><img
          alt="Download on the app store"
          src="/img/download-on-the-app-store.svg"
      /></a>
      <!-- <a
        href="https://play.google.com/store/apps/details?id=com.polarbeaver.boost_art_net&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener noreferrer"
        ><img alt="Get it on Google Play" src="/img/google-play-badge.png"
      /></a> -->
    </div>
    <ul class="list">
      <li class="item">
        <a href="/privacy-policy.html">Privacy</a>
      </li>
      <li class="item">
        <a href="/terms-and-conditions.html">Terms & Conditions</a>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less" scoped>
.footer {
  position: absolute;
  bottom: 0;
  height: 7rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-start;
  vertical-align: middle;

  .app-badge {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tagline {
      background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    a:first-of-type {
      margin-right: 0;
      transform: scale(0.78);
      position: relative;
      top: 0.1rem;
    }
    a {
      margin-right: 1rem;
      img {
        width: 10rem;
      }
    }
  }
  .list {
    width: 100%;
    margin-right: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    .item a {
      color: rgba(139, 139, 139, 0.552);
      &:hover {
        color: rgba(239, 247, 255, 0.615);
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    display: none;
  }
}
</style>
